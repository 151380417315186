import React from 'react'

import { Link } from 'gatsby'

import * as styles from './styles.module.scss'

import Sphere from './sphere'

const SuccessBlock = ({ count }) => {
  return (
    <section className={styles.succesesBlock}>
      <div className={`${styles.succesesBlockContainer} container`}>
        <div className={styles.succesesBlockInner}>
          <Sphere isPlay={true} />
        </div>
        <h1 className={styles.succesesBlockSubtitle}>Thank You!</h1>
        <h2 className={styles.succesesBlockSubtitle}>
          Your message has successfully been sent. We received it! And will
          contact you shortly.
        </h2>
        <h2 className={styles.succesesBlockSubtitle}>
          We received it! And will contact you shortly.
        </h2>
        <Link to="/" className={styles.succesesBlockBtn}>
          Home page
        </Link>
        <p className={styles.countText}>
          This page is automatically redirected after {count} seconds.
        </p>
      </div>
    </section>
  )
}

export default SuccessBlock
