import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'

import Seo from '../../components/Seo'
import SuccessBlock from '../../components/mainComponents/SuccesBlock/SuccessBlock'

const NotFoundPage = () => {
  const [count, setCount] = useState(7)

  useEffect(() => {
    let timeout = setTimeout(() => {
      setCount((prevState) => prevState - 1)
    }, 1000)
    if (!count) navigate('/')

    return () => {
      clearTimeout(timeout)
    }
  }, [count])

  return (
    <>
      <Seo
        title="Success"
        htmlAttributes={{
          lang: 'en',
        }}
        url="https://www.it-justice.com/"
        description="This page is automatically redirected"
      />
      <SuccessBlock count={count} />
    </>
  )
}

export default NotFoundPage
